.dhx_cal_navline button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.dhx_cal_navline button:hover {
  background-color: #0056b3;
}

.dhx_cal_navline input, .dhx_cal_navline select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}
.dhx_cal_navline {
  background-color:hsl(240, 3%, 95%) !important;
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-trigger:hover + .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.tooltip-content {
  visibility: hidden;
  width: 80px;
  height:20px;
  color: #696969;
  text-align: center;
  border-radius: 5px;
  padding: 2px;
  position: absolute;
  z-index: 1;
  top: 70%;
  left: 55%;
  margin-left: -40px;
  margin-top: 8px;
  opacity: 0;
  transition: opacity 0.5s;
}
.sidebar {
  position: fixed;
  top: 0;
  right: -300px; /* Start hidden off-screen */
  width: 300px;
  height: 100%;
  background: #f4f4f4;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px;
  transition: right 0.3s ease-in-out;
}

.sidebar.open {
  right: 0; /* Slide in */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}