.sidebar {
    position: fixed;
    top: 0;
    right: -300px; /* Start hidden off-screen */
    width: 300px;
    height: 100%;
    background: #f4f4f4;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    padding: 20px;
    transition: right 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .sidebar.open {
    right: 0; /* Slide in */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .dashboard-form-group {
    margin-bottom: 15px;
  }
  
  .dashboard-form-label {
    display: block;
    margin-bottom: 5px;
  }
  
  .dashboard-form-control {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .dashboard-submit-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .dashboard-submit-button:hover {
    background-color: #0056b3;
  }
  