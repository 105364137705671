.Login-container {
    margin: 0;
    font-family: 'Arial', sans-serif;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .container {
    width: 900px;
    height: 500px;
    display: flex;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Left panel styling */
  .Login-left-panel {
    flex: 1;
    background-image: url('../assets/images/20547283_6310507.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items at the top */
    align-items: center;
    padding-top: 20px; /* Add padding to move content slightly down */
    color: rgba(61, 60, 60, 0.802);
  }
  
  .Login-left-panel h1 {
    margin-top: 0;
    text-align: center;
  }
  
  .Login-left-panel p {
    margin-top: 0px;
    text-align: center;
  }
  
  /* Right panel styling */
  .Login-right-panel {
    flex: 1;
    padding: 40px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Align content to space-between */
  }
  
  /* Form styling */
  .Login-form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .Login-form-group h1 {
    color: rgba(61, 60, 60, 0.802);
    margin-bottom: 30px;
    text-align: center;
  }
  
  .Login-input-group {
    margin-bottom: 20px;
    width: 100%;
    margin-left: 20px;
  }
  
  .Login-input-group label {
    display: block;
    margin-bottom: 5px;
    color: rgba(61, 60, 60, 0.802);
  }
  
  .Login-input-group input {
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    
  }
  
  /* Button container styling */
  .Login-button-container {
    display: flex;
    justify-content: flex-end; /* Align the button to the right */
  }
  
  /* Submit button styling */
  .Login-submit-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
    margin-right: 38px;
    width: 30%;
  }

  .Login-submit-button:hover {
    background-color: #0056b3;
  }
  