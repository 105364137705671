/* pages/NotFound.css */
.not-found {
    text-align: center;
    padding: 50px;
  }
  
  .not-found h1 {
    font-size: 100px;
    margin-bottom: 20px;
  }
  
  .not-found p {
    font-size: 20px;
    margin-bottom: 30px;
  }
  
  .not-found a {
    font-size: 18px;
    color: #007bff;
    text-decoration: none;
  }
  
  .not-found a:hover {
    text-decoration: underline;
  }
  